import React, { useState, useCallback, useEffect } from "react";

import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import parser from "html-react-parser";

import { axiosSendEmailRoute, axiosDownloadPdfRoute } from "../helpers/axios";
// import { Buffer } from "buffer";

import DownloadIcon from "@mui/icons-material/Download";
import CircularProgress from "@mui/material/CircularProgress";

const EmailPreviewModal = ({
  previewEmail,
  setPreviewEmail,
  token,
  notify,
  getFutureEmails,
  emailQueryMode,
}) => {
  const [loadingDyn, setLoadingDyn] = useState(false);

  const emailPreviewHandler = useCallback(async () => {
    setLoadingDyn(true);
    try {
      let result;

      result = await axiosSendEmailRoute.delete(`/${previewEmail.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (result?.data) {
        getFutureEmails(emailQueryMode);
        setPreviewEmail(null);
        setLoadingDyn(false);
      }
    } catch (err) {
      setLoadingDyn(false);
      notify("info", err.response.data.error.message);
    }
  }, [
    notify,
    token,
    setPreviewEmail,
    getFutureEmails,
    previewEmail,
    emailQueryMode,
  ]);

  // const decodePdf = (data) => {
  //   const file = new Blob([Buffer.from(data, "base64")], {
  //     type: "application/pdf;base64",
  //   });
  //   const fileURL = URL.createObjectURL(file);
  //   window.open(fileURL);
  // };

  // return loadingDyn === params.node.data.id ? (
  //   <CircularProgress className="mt-1" size={25} />
  // ) : (
  //   <DownloadIcon
  //     className="cursor-pointer"
  //     onClick={() => downloadFile("getfiles", [params.node.data.id])}
  //   />
  // );

  const RejectionText = ({ rejectionData }) => {
    return (
      <div>
        {rejectionData.map((bounce, index) => (
          <div key={index}>
            <strong>Email Address:</strong> {bounce.emailAddress}
            <br />
            <strong>Action:</strong> {bounce.action}
            <br />
            <strong>Status:</strong> {bounce.status}
            <br />
            <strong>Diagnostic Code:</strong> {bounce.diagnosticCode}
            <br />
            <br />
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    if (previewEmail?.attributes?.rejection_object?.length > 0) {
      const rejectionData = previewEmail.attributes.rejection_object;

      notify("error", <RejectionText rejectionData={rejectionData} />, {
        position: "top-left",
        draggable: false,
        closeOnClick: false,
        autoClose: false,
        style: {
          width: "800px",
        },
      });
    }
  }, [notify, previewEmail]);

  const downloadFile = useCallback(
    async (type, idselection = [], mode) => {
      type === "getfiles" && setLoadingDyn(idselection[0]);
      // e.preventDefault();
      const data = {
        type,
        mode,
        idselection,
        searchbyseminarid: null,
      };

      try {
        let result;

        result = await axiosDownloadPdfRoute.post(
          `/`,
          {
            data: data,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/pdf",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (result?.data) {
          await (async () => {
            for await (let file of result?.data) {
              const newfile = new Blob([
                new Uint8Array(file.binary.data).buffer,
              ]);
              const url = window.URL.createObjectURL(newfile);
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", file.name);
              document.body.appendChild(link);
              link.click();
            }
          })();

          setLoadingDyn(false);
        }
      } catch (err) {
        notify("info", err.response.data.error.message);

        setLoadingDyn(false);
      }
    },
    [notify, token]
  );

  return (
    <Modal
      open={previewEmail !== null}
      aria-describedby="modal-modal-description"
      onClose={() => {
        setLoadingDyn(false);
      }}
    >
      <Box
        sx={{ overflowY: "scroll", maxHeight: "100%" }}
        className="customized-scrollbar h-screen w-screen p-4"
      >
        <div
          id="email-send"
          className="p-4 h-full flex items-center justify-center"
        >
          <div className="bg-white p-4 relative inline-block align-bottom rounded-lg shadow-xl transform transition-all w-1/2 overflow-auto max-h-full customized-scrollbar">
            <>
              <b>{parser(previewEmail?.attributes?.subject || "")}</b>
              <div className="pb-4">
                <br />

                {parser(previewEmail?.attributes?.body || "")}
              </div>
              {previewEmail?.attributes?.attachments?.data?.length > 0 &&
                previewEmail?.attributes?.attachments.data.map((attachment) => {
                  return (
                    <div
                      onClick={() => downloadFile("getfiles", [attachment.id])}
                      className="flex underline w-full"
                    >
                      <div className="cursor-pointer">
                        {attachment?.attributes?.name || ""}
                      </div>
                      <div className="pl-2">
                        {loadingDyn === attachment.id ? (
                          <CircularProgress
                            className="cursor-pointer"
                            size={18}
                          />
                        ) : (
                          <DownloadIcon className="cursor-pointer" />
                        )}
                      </div>
                    </div>
                  );
                })}
            </>

            <div className="flex justify-end">
              <button
                onClick={() => {
                  setPreviewEmail(null);
                }}
                className="whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-900 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-700"
              >
                {"Fertig"}
              </button>

              {emailQueryMode === "future" && (
                <button
                  disabled={loadingDyn}
                  name="nicht_senden"
                  onClick={emailPreviewHandler}
                  className={`
                bg-primary-900 disabled:opacity-40
                   ml-5 relative whitespace-nowrap inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`}
                >
                  {"Löschen"}
                </button>
              )}
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default EmailPreviewModal;
