import React, { useState, useCallback, useEffect, useMemo } from "react";

import Fuse from "fuse.js";
import { AgGridReact } from "ag-grid-react";

import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import ContentConpyIcon from "@mui/icons-material/ContentCopy";
// import EditIcon from "@mui/icons-material/Edit";
import NextIcon from "@mui/icons-material/NavigateNext";
import BeforeIcon from "@mui/icons-material/NavigateBefore";
import IconButton from "@mui/material/IconButton";
import SearchButton from "@mui/icons-material/Search";
import LoadingButton from "@mui/lab/LoadingButton";
import ClearButton from "@mui/icons-material/Clear";

import AG_GRID_LOCALE_DE from "./locale.de";

// import Checkbox from "@mui/material/Checkbox";

import { useLocation /*useNavigate*/, useSearchParams } from "react-router-dom";

import { DatePicker } from "@mantine/dates";

const SeminarSelectGrid = ({
  filter,
  seminarSelection,
  setSeminarInputs,
  seminarInputs,
  setOpen,
  gridRefSeminar,
  editSeminar,
  setEditSeminar,
  idFilter,
  exactDateFilter,
  loadingDyn,
  filterHandler,
  query,
  setQuery,
  selectSeminarOnFirstLoad,
  setSelectSeminarOnFirstLoad,
  triggerSeminarSelect,
  setTriggerSeminarSelect,
}) => {
  const localeText = useMemo(() => {
    return AG_GRID_LOCALE_DE;
  }, []);
  const location = useLocation();

  const [seminarQueryResults, setSeminarQueryResults] = useState([]);
  const [searchParams] = useSearchParams();

  let page = null;

  if (location.pathname === "/seminare/seminarzusammenfassung") {
    page = "seminarzusammenfassung";
  } else if (location.pathname === "/seminare/approvaladmin") {
    page = "approvaladmin";
  } else if (location.pathname === "/seminare/semverwaltung") {
    page = "semverwaltung";
  } else if (location.pathname === "/seminare/bestsemverwaltung") {
    page = "bestsemverwaltung";
  } else if (location.pathname === "/rechnungen") {
    page = "rechnungen";
  } else {
    page = null;
  }

  useEffect(() => {
    let date = new Date();

    // var userTimezoneOffset = date.getTimezoneOffset() * 60000;
    // date = new Date(date.getTime() - userTimezoneOffset);

    const result = date.toISOString("en-DE", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    const options = {
      includeScore: true,
      useExtendedSearch: true,
      ignoreLocation: true,
      threshold: 0.2,
      keys: ["attributes.thema", "attributes.datum", "id"],
    };

    const fuse = new Fuse(seminarSelection, options);
    if (seminarSelection?.length > 0) {
      setSeminarQueryResults(
        fuse.search(
          query.thema !== "" ||
            (filter === "approveadmin" &&
              (query.semid !== "" || query.anfangsdatum !== ""))
            ? {
                $and: [
                  ...(query.thema && [
                    {
                      $path: ["attributes.thema"],
                      $val: query.thema,
                    },
                  ]),
                  ...(query.semid && [
                    {
                      $path: ["id"],
                      $val: query.semid,
                    },
                  ]),
                  ...(query.anfangsdatum && [
                    {
                      $path: ["attributes.datum"],
                      $val: `=${query.anfangsdatum.toISOString()}`,
                    },
                  ]),
                ],
              }
            : {
                "attributes.datum":
                  filter === "currentmonth" ? `^${result.slice(0, 7)}` : "'2",
              }
        )
      );
    } else {
      setSeminarQueryResults([]);
    }

    // eslint-disable-next-line
  }, [query, seminarSelection, filter]);

  const onSeminarSelectionChanged = (event) => {
    if (event?.api?.getFocusedCell()?.column?.getColId() !== "wp_link") {
      if (event.node.isSelected()) {
        if (
          event?.api?.getFocusedCell()?.column?.getColId() !== "copy" &&
          event?.api?.getFocusedCell()?.column?.getColId() !== "edit"
        ) {
          setEditSeminar(false);
          setSeminarInputs({});
        }
        event.node.setSelected(false);
      } else {
        event.node.setSelected(true);
        setSeminarInputs(event.data);
      }
    }
  };

  const navigateToNextRow = async (e, direction) => {
    e.preventDefault();

    // get all rows as array

    let selectedNode = gridRefSeminar.current.api.getSelectedNodes()[0];

    // deselect selected node

    selectedNode.setSelected(false);

    await gridRefSeminar.current.api.setFilterModel(null);

    gridRefSeminar.current.api.onFilterChanged();

    setEditSeminar(false);
    setSeminarInputs({});

    let getindex = 0;

    gridRefSeminar.current.api.forEachNodeAfterFilterAndSort((node, ind) => {
      if (Number(selectedNode.data.id) === Number(node.data.id)) {
        getindex = ind + direction;
      }
    });

    const rowCountIndex = gridRefSeminar.current.api.getDisplayedRowCount() - 1;

    if (getindex < 0) {
      getindex = rowCountIndex;
    } else if (getindex > rowCountIndex) {
      getindex = 0;
    }

    gridRefSeminar.current.api.forEachNodeAfterFilterAndSort((node, ind) => {
      if (ind === getindex) {
        node.setSelected(true, true);
        setSeminarInputs(node.data);
      }
    });
  };

  const columnSeminarDefs = useMemo(
    () => [
      {
        width: 45,
        headerClass: "header-blue",
        suppressSizeToFit: true,
        checkboxSelection: true,
        headerCheckboxSelection: page === "seminarzusammenfassung",
        cellStyle: { pointerEvents: "none" },
      },
      {
        field: "id",
        headerName: "Seminar NR",
        sortable: true,
        width: 97,
        minWidth: 97,
        headerClass: "header-blue",
        suppressSizeToFit: true,
      },
      {
        field: "wp_link",
        headerName: "Web",
        width: 50,
        cellStyle: { padding: 4 },
        headerClass: "header-blue",
        resizable: false,
        cellRenderer: (params) => {
          return params.data.wp_published ? (
            <a
              rel="noreferrer"
              target="_blank"
              href={`${process.env.REACT_APP_WP_SITE}/${params.value}`}
            >
              <LinkIcon
                fontSize="medium"
                className="cursor-pointer flex h-full"
              />
            </a>
          ) : (
            <LinkOffIcon
              fontSize="medium"
              className="pointer-events-none opacity-10 flex h-full"
            />
          );
        },

        suppressSizeToFit: true,
        //hide: page === "approvaladmin",
      },
      {
        field: "copy",
        headerName: "",
        width: 30,
        minWidth: 30,
        cellStyle: { padding: 4 },
        headerClass: "header-blue",
        resizable: false,
        cellRenderer: (params) => {
          return (
            <ContentConpyIcon
              onClick={() => seminarInputs.id && setOpen("copy")}
              className={
                params.node.selected &&
                seminarInputs.id &&
                gridRefSeminar?.current?.api?.getSelectedNodes().length === 1
                  ? "opacity-100 cursor-pointer flex h-full"
                  : "opacity-30 flex pointer-events-none h-full"
              }
              fontSize="small"
            />
          );
        },
        hide:
          page === "approvaladmin" ||
          page === "bestsemverwaltung" ||
          page === "rechnungen",
      },
      {
        field: "datum_friendly_adjusted",
        headerName: "Datum",
        sort: "asc",
        width: 110,
        minWidth: 110,
        sortable: true,
        headerClass: "header-blue",
        resizable: true,
        comparator: (date1, date2) => {
          // date format is "mm.dd.yyyy"

          const date1ISO = date1.split(".").reverse().join("-");

          const date2ISO = date2.split(".").reverse().join("-");

          // Compare ISO dates directly
          return date1ISO < date2ISO ? -1 : date1ISO > date2ISO ? 1 : 0;
        },
      },
      {
        field: "end_datum_friendly_adjusted",
        headerName: "Enddatum",
        sortable: true,
        width: 110,
        minWidth: 110,
        headerClass: "header-blue",
        resizable: true,
        comparator: (date1, date2) => {
          // date format is "mm.dd.yyyy"

          const date1ISO = date1.split(".").reverse().join("-");

          const date2ISO = date2.split(".").reverse().join("-");

          // Compare ISO dates directly
          return date1ISO < date2ISO ? -1 : date1ISO > date2ISO ? 1 : 0;
        },
      },
      {
        field: "anfangs_zeit_friendly_adjusted",
        headerName: "Anfang",
        sortable: true,
        width: 95,
        minWidth: 95,
        sort: "asc",
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "end_zeit_friendly_adjusted",
        headerName: "Ende",
        sortable: true,
        width: 95,
        minWidth: 95,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "thema",
        headerName: "Seminar-Thema",
        sortable: true,
        minWidth: 200,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "raum_details.ort",
        headerName: "Ort",
        sortable: true,
        width: 100,
        minWidth: 100,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "raum_details.bezeichnung",
        headerName: "Räume",
        sortable: true,
        width: 145,
        minWidth: 100,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "referent_details",
        headerName: "Referent/-in",
        sortable: true,
        width: 150,
        minWidth: 100,
        headerClass: "header-blue",
        resizable: true,
        hide: page === "approvaladmin",
        valueGetter: (params) => {
          return `${
            params?.data.referent_details?.name
              ? params?.data?.referent_details?.name + ","
              : ""
          } ${params?.data?.referent_details?.vorname || ""}`;
        },
      },
      {
        field: "max_teilnehmer",
        headerName: "TN",
        sortable: true,
        width: 80,
        minWidth: 80,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "max_tn_zahl",
        headerName: "Max TN",
        sortable: true,
        width: 80,
        minWidth: 80,
        headerClass: "header-blue",
        resizable: true,
      },
      {
        field: "anzahl_skripten",
        headerName: "Gedruckt",
        sortable: true,
        width: 80,
        minWidth: 80,
        headerClass: "header-blue",
        resizable: true,
        //hide: page === "approvaladmin",
      },
    ],
    [seminarInputs.id, setOpen, page, gridRefSeminar]
  );

  const isExternalFilterPresent = useCallback(() => {
    return seminarInputs.id !== undefined && page !== "seminarzusammenfassung";
  }, [seminarInputs.id, page]);

  useEffect(() => {
    if (gridRefSeminar?.current?.api && page === "semverwaltung") {
      // resetQuery

      if (!seminarInputs.id) {
        gridRefSeminar.current.api.deselectAll();
      }
      gridRefSeminar.current.api.onFilterChanged();
    }
  }, [seminarInputs.id, gridRefSeminar, page]);

  const getRowId = useMemo(() => {
    return (params) => {
      return params.data.id;
    };
  }, []);

  useEffect(() => {
    if (searchParams.get("seminarid")) {
      setQuery((prior) => ({
        ...prior,
        semid: searchParams.get("seminarid"),
      }));
    }
  }, [location.search, setQuery, searchParams]);

  return (
    <div className="col-span-12">
      <div
        className={`${
          editSeminar && "opacity-60 pointer-events-none"
        } col-span-12 flex mb-4`}
      >
        <div className="w-3/12 flex mr-5">
          <div className="whitespace-nowrap flex items-center">semID:</div>
          <div className="relative w-full overflow-hidden bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
            <input
              disabled={loadingDyn}
              className="disabled:opacity-80 before:ml-5 w-full p-1 outline-none sm:text-sm border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0"
              value={query?.semid}
              onKeyDown={(e) => {
                if (e.key === "Enter" && query.semid) {
                  filterHandler(e, "semid", true);
                }
              }}
              onChange={(e) =>
                setQuery((prior) => ({
                  ...prior,
                  semid: e.target.value,
                }))
              }
            />

            {filter !== "approveadmin" && (
              <div className="absolute inset-y-0 right-0 flex items-center justify-center">
                <LoadingButton
                  sx={{ minWidth: "auto", padding: 0 }}
                  disableRipple
                  loading={loadingDyn === "semid"}
                  disabled={query?.semid ? false : true}
                  onClick={(e) => filterHandler(e, "semid", true)}
                >
                  {<SearchButton />}
                </LoadingButton>
                {idFilter && loadingDyn === false && (
                  <IconButton
                    color="warning"
                    sx={{ padding: 0 }}
                    disabled={loadingDyn === "semid"}
                    onClick={(e) => filterHandler(e, "semid", false)}
                    disableRipple
                  >
                    <ClearButton />
                  </IconButton>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="w-7/12 flex mr-10">
          <div className="whitespace-nowrap flex items-center">Thema:</div>
          <input
            className="ml-5 h-min w-full p-1 border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
            value={query?.thema}
            onChange={(e) => {
              setQuery((prior) => ({
                ...prior,
                thema: e.target.value,
              }));
              // on change we want to lose all selections
              if (seminarInputs.id) {
                setSeminarInputs({});
              }
            }}
          />
        </div>

        <div className="w-2/12 flex justify-end mr-5 relative">
          <DatePicker
            clearable={filter !== "approveadmin" ? false : true}
            classNames={{ input: "placeholder:text-black" }}
            placeholder="Datum wählen"
            inputFormat="DD.MM.YYYY"
            className="w-full h-[29px] border-0 border-b border-gray-300 bg-gray-50 hover:bg-primary-50 hover:border-primary-600 focus:border-primary-600 focus:ring-0 outline-none sm:text-sm"
            variant="unstyled"
            // size="xs"
            value={query.anfangsdatum === "" ? null : query.anfangsdatum}
            onChange={(newValue) => {
              setQuery((prior) => ({
                ...prior,
                anfangsdatum: newValue === null ? "" : newValue,
              }));
            }}
          />
          {filter !== "approveadmin" && (
            <div className="absolute inset-y-0 right-0 flex items-center justify-center">
              <LoadingButton
                sx={{ minWidth: "auto", padding: 0 }}
                disableRipple
                loading={loadingDyn === "exactdate"}
                disabled={query?.anfangsdatum ? false : true}
                onClick={(e) => filterHandler(e, "exactdate", true)}
              >
                {<SearchButton />}
              </LoadingButton>
              {exactDateFilter && loadingDyn === false && (
                <IconButton
                  color="warning"
                  sx={{ padding: 0 }}
                  disabled={loadingDyn === "exactdate"}
                  onClick={(e) => filterHandler(e, "exactdate", false)}
                  disableRipple
                >
                  <ClearButton />
                </IconButton>
              )}
            </div>
          )}
        </div>
      </div>
      <div
        style={
          page === "seminarzusammenfassung"
            ? { height: "79vh" }
            : seminarInputs.id && page === "semverwaltung"
            ? { height: 152 }
            : { height: 450 }
        }
        className="ag-theme-alpine w-full col-span-12"
      >
        <AgGridReact
          enableCellTextSelection
          defaultColDef={{
            filter: false,
          }}
          rowData={seminarQueryResults.map((client) => ({
            ...client.item.attributes,
            id: client.item.id,
          }))}
          columnDefs={columnSeminarDefs}
          rowSelection={
            page === "seminarzusammenfassung" ? "multiple" : "single"
          }
          onRowClicked={onSeminarSelectionChanged}
          ref={gridRefSeminar}
          rowHeight={33}
          localeText={localeText}
          getRowId={getRowId}
          onFirstDataRendered={(e) => {
            if (page === "semverwaltung" && selectSeminarOnFirstLoad) {
              e.api.forEachNode((node) => {
                if (node.rowIndex === 0) {
                  setSeminarInputs(node.data);
                  node.setSelected(true, true);
                  setSelectSeminarOnFirstLoad(false);
                  return;
                }
              });
            }
            seminarInputs.id &&
              e.api.forEachNode((node) => {
                node.data.id === seminarInputs.id &&
                  node.setSelected(true, true);
              });
          }}
          onRowDataUpdated={(e) => {
            if (triggerSeminarSelect) {
              e.api.forEachNode((node) => {
                if (node.data.id === seminarInputs.id) {
                  setSeminarInputs(node.data);
                  node.setSelected(true, true);
                  setTriggerSeminarSelect(false);
                }
              });
            }
          }}
          paginationPageSize={page === "seminarzusammenfassung" ? 90 : 90}
          pagination={
            page === "rechnungen" || page === "approvaladmin" ? false : true
          }
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={(e) => e.data.id === seminarInputs.id}
          suppressRowClickSelection
        ></AgGridReact>

        {seminarInputs.id && page !== "seminarzusammenfassung" ? (
          //absolute -bottom-14 inset-x-0
          <div className="flex justify-center mt-1">
            <IconButton
              // disabled={seminarInputs.id === seminarSelection[0].id}
              className="pr-2 "
              onClick={(e) => navigateToNextRow(e, -1)}
              aria-label="forward"
            >
              <BeforeIcon fontSize="large" />
            </IconButton>
            <IconButton
              // disabled={
              //   seminarInputs.id ===
              //   seminarSelection[seminarSelection.length - 1].id
              // }
              className="pl-2 "
              onClick={(e) => navigateToNextRow(e, +1)}
              aria-label="back"
            >
              <NextIcon fontSize="large" />
            </IconButton>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SeminarSelectGrid;
